import "./global.css";
import { createTheme, Theme } from "@mui/material/styles";
import { convertPxToRem } from "../utils";
import { Shadows } from "@mui/material/styles/shadows";

declare module "@mui/material/styles" {
  interface Palette {
    errorInverse: Palette["primary"];
    neutral: Palette["primary"];
    whatsApp: Palette["primary"];
    intercare: Palette["primary"];
    statusGreen: Palette["primary"];
    royalBlue: Palette["primary"];
    accent1: Palette["primary"];
    accent2: Palette["primary"];
    accent3: Palette["primary"];
    accent4: Palette["primary"];
    accent5: Palette["primary"];
  }
  interface PaletteOptions {
    errorInverse?: PaletteOptions["primary"];
    neutral?: PaletteOptions["primary"];
    whatsApp?: PaletteOptions["primary"];
    intercare?: PaletteOptions["primary"];
    statusGreen?: PaletteOptions["primary"];
    royalBlue?: PaletteOptions["primary"];
    accent1: PaletteOptions["primary"];
    accent2: PaletteOptions["primary"];
    accent3: PaletteOptions["primary"];
    accent4: PaletteOptions["primary"];
    accent5: PaletteOptions["primary"];
  }
  interface PaletteColor {
    lightest?: string;
  }
  interface SimplePaletteColorOptions {
    lightest?: string;
    lighter?: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    errorInverse: true;
    neutral: true;
    whatsApp: true;
    intercare: true;
    statusGreen: true;
    accent1: true;
    accent2: true;
    accent3: true;
    accent4: true;
    accent5: true;
  }
}

declare module "@mui/material/ToggleButtonGroup" {
  interface ToggleButtonGroupPropsColorOverrides {
    errorInverse: true;
    neutral: true;
    whatsApp: true;
    intercare: true;
    statusGreen: true;
    accent1: true;
    accent2: true;
    accent3: true;
    accent4: true;
    accent5: true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    accent1: true;
    accent2: true;
    accent3: true;
    accent4: true;
    accent5: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    neutral: true;
    accent1: true;
    accent2: true;
    accent3: true;
    accent4: true;
    accent5: true;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteColor extends ColorPartial {}
}

const fonts = {
  default: ['"Manrope"', '"Arial"', "sans-serif"].join(","),
};

const shadowList: Shadows = [
  "none",
  "none",
  "none",
  "0 1px 2px rgba(0, 0, 0, 0.03), 0 3px 8px rgba(0, 0, 0, 0.04), 0 10px 20px rgba(0, 0, 0, 0.07)",
  "none",
  "none",
  "0 2px 3px 0 rgba(0, 0, 0, 0.03), 0 4px 10px 0 rgba(0, 0, 0, 0.04), 0 18px 47px 0 rgba(0, 0, 0, 0.07)",
  "none",
  "none",
  "none",
  "none",
  "none",
  "0 3px 2px 0 rgba(0, 0, 0, 0.02), 0 7px 5px 0 rgba(0, 0, 0, 0.03), 0 12px 10px 0 rgba(0, 0, 0, 0.04), 0 22px 18px 0 rgba(0, 0, 0, 0.04), 0 -2px 28px 0 rgba(0, 0, 0, 0.05)",
  "none",
  "none",
  "none",
  "none",
  "none",
  "0 3px 2px 0 rgba(0, 0, 0, 0.02), 0 7px 5px 0 rgba(0, 0, 0, 0.03), 0 12px 10px 0 rgba(0, 0, 0, 0.04), 0 22px 18px 0 rgba(0, 0, 0, 0.04), 0 42px 33px 0 rgba(0, 0, 0, 0.05), 0 100px 80px 0 rgba(0, 0, 0, 0.07)",
  "none",
  "none",
  "none",
  "none",
  "none",
  "0 3px 4px 0 rgba(0, 0, 0, 0.03), 0 7px 10px 0 rgba(0, 0, 0, 0.04), 0 13px 18px 0 rgba(0, 0, 0, 0.05), 0 22px 32px 0 rgba(0, 0, 0, 0.06), 0 42px 61px 0 rgba(0, 0, 0, 0.07), 0 100px 145px 0 rgba(0, 0, 0, 0.15)",
];

export const maxWidthConfig = {
  defaultMaxWidth: "600px",
  playgroundMaxWidth: "1440px",
};

export const shadows = {
  none: 0,
  lightest: 3,
  light: 6,
  medium: 12,
  dark: 18,
  heavy: 24,
};

export const maxWidthContainerStyling = {
  height: "100%",
  maxWidth: (theme: Theme) => theme.spacing(75),
  width: "100%",
  mx: "auto",
  position: "relative",
  zIndex: 1,
};

export const baseButtonStyle = {
  borderRadius: 2,
  borderWidth: 2,
  fontSize: convertPxToRem(15),
  fontWeight: 700,
  lineHeight: 1.6,
  [`&:hover, &:active, &:focus, &:disabled`]: {
    borderWidth: 2,
  },
  [`&:focus`]: {
    outlineStyle: "solid",
    outlineWidth: 2,
  },
};

export const slideUpModalStyle = {
  px: 2,
  py: 4,
  position: "absolute",
  bottom: 0,
  margin: 0,
  width: "100vw",
  maxHeight: "95%",
  borderRadius: (theme: Theme) => `${theme.spacing(3)} ${theme.spacing(3)} 0 0`,
};

export const baseToggleButtonStyle = {
  ...baseButtonStyle,
  borderStyle: "solid",
};

export const inputContainerDefaultStyle = {
  borderRadius: 2,
};

export const styleInputDefault = {
  fontSize: convertPxToRem(26),
  height: "auto",
  lineHeight: 1,
  px: 2,
  py: 1.5,

  "&.MuiInputBase-inputSizeSmall": {
    fontSize: convertPxToRem(16),
    py: 1.75,
  },
};

export const styleInputPrimaryCenteredBold = {
  color: "primary.main",
  fontWeight: "700",
  textAlign: "center",
};

export const modalContentStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "40rem",
  minWidth: "85vw",
  bgcolor: "background.paper",
  boxShadow: shadows.heavy,
  borderRadius: 4,
};

export const panelStyle = {
  backgroundColor: "background.paper",
  borderRadius: 2,
  boxShadow: shadows.light,
  p: 2,
  overflow: "hidden",
  position: "relative",
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#4e7fff",
      light: "#a3b7e4",
      dark: "#1450ec",
      contrastText: "#fff",
      100: "#e1e8f6",
      300: "#d1dbf2",
      500: "#a3b7e4",
      700: "#4e7fff",
      900: "#1450ec",
    },
    secondary: {
      main: "#ffbe40",
      light: "#ffe6c9",
      dark: "#e89900",
      contrastText: "#fff",
      100: "#fcf2e7",
      300: "#ffeed9",
      500: "#ffe6c9",
      700: "#ffbe40",
      900: "#e89900",
    },
    neutral: {
      main: "#494d55",
      light: "#8a909b",
      dark: "#212121",
      contrastText: "#fff",
      50: "#fff",
      100: "#f2f3f3",
      300: "#d0d3d7",
      500: "#8a909b",
      700: "#494d55",
      900: "#212121",
    },
    accent1: {
      main: "#00948d",
      light: "#92e1db",
      dark: "#02635e",
      contrastText: "#fff",
      100: "#e0f7f5",
      300: "#beede9",
      500: "#92e1db",
      700: "#00948d",
      900: "#02635e",
    },
    accent2: {
      lightest: "#ecf4fc",
      lighter: "#e3f1ff",
      light: "#d7ebff",
      main: "#83c5ff",
      dark: "#43a4f9",
      100: "#ecf4fc",
    },
    accent3: {
      main: "#f39a74",
      light: "#ffc6ae",
      dark: "#c6704c",
      contrastText: "#fff",
      100: "#ffece3",
      300: "#ffddce",
      500: "#ffc6ae",
      700: "#f39a74",
      900: "#c6704c",
    },
    accent4: {
      lightest: "#fbebea",
      lighter: "#ffd3d2",
      light: "#ffc8c7",
      main: "#e84f3e",
      dark: "#c21300",
    },
    accent5: {
      lightest: "#f9e9f8",
      lighter: "#f5ddf8",
      light: "#eabaf0",
      main: "#d680e0",
      dark: "#a55dad",
    },
    info: {
      main: "#fff",
      light: "#fff",
      dark: "#fff",
      contrastText: "#4e7fff",
    },
    error: {
      main: "#e84f3e",
      contrastText: "#fff",
    },
    success: {
      main: "#16c050",
      contrastText: "#fff",
    },
    errorInverse: {
      main: "#fff",
      contrastText: "#e84f3e",
    },
    whatsApp: {
      main: "#63C971",
      contrastText: "#fff",
    },
    intercare: {
      main: "#004579",
      dark: "#032137",
      contrastText: "#fff",
    },
    statusGreen: {
      main: "#16c050",
      light: "#63c971",
      contrastText: "#fff",
    },
    royalBlue: {
      main: "#102D93",
      contrastText: "#fff",
      700: "#0C267D",
      900: "#000E86",
    },
  },
  typography: {
    fontFamily: fonts.default,
    allVariants: {
      color: "#212121",
    },
    h1: {
      fontSize: convertPxToRem(34),
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: convertPxToRem(24),
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: convertPxToRem(20),
      fontWeight: 500,
      letterSpacing: "-0.04em",
      lineHeight: 1.2,
    },
    h4: {
      fontSize: convertPxToRem(15),
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: convertPxToRem(12),
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: convertPxToRem(10),
      fontWeight: 600,
      lineHeight: 1.2,
    },
    subtitle1: {
      color: "#8a909b",
      fontSize: convertPxToRem(12),
      fontWeight: 600,
      lineHeight: 1.2,
    },
    subtitle2: {
      color: "#8a909b",
      fontSize: convertPxToRem(10),
      fontWeight: 600,
      lineHeight: 1.2,
    },
    body2: {
      fontSize: convertPxToRem(12),
    },
    button: {
      textTransform: "none",
    },
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        sx: {
          borderRadius: baseButtonStyle.borderRadius,
          borderWidth: baseButtonStyle.borderWidth,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        size: "medium",
        variant: "contained",
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...baseButtonStyle,
          borderRadius: 8,

          ...((ownerState.variant === "contained" ||
            ownerState.variant === "text") && {
            borderWidth: baseButtonStyle.borderWidth,
            borderColor: "transparent",
            borderStyle: "solid",
          }),

          ...(ownerState.size === "small" && {
            padding: `${theme.spacing(0.75, 1)}`,
          }),

          ...(ownerState.size === "medium" && {
            padding: `${theme.spacing(1.5, 2)}`,
          }),

          ...(ownerState.size === "large" && {
            padding: `${theme.spacing(2.5, 2)} `,
          }),

          "&.Mui-disabled": {
            borderColor: "transparent",
          },
        }),
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
        variant: "contained",
      },
    },
    MuiToggleButton: {
      defaultProps: {
        disableFocusRipple: true,
        size: "medium",
        sx: {
          ...baseButtonStyle,
          "&:focus": {
            outlineStyle: "solid",
            outlineWidth: 1,
          },

          "&.MuiButtonBase-root.MuiToggleButton-root": {
            borderWidth: baseButtonStyle.borderWidth,

            "&.MuiToggleButton-neutral": {
              backgroundColor: "neutral.contrastText",
              borderColor: "neutral.main",
              color: "neutral.main",

              "&.Mui-selected": {
                backgroundColor: "neutral.main",
                color: "neutral.contrastText",

                "&:focus": {
                  outlineColor: "neutral.main",
                },
              },

              "&.Mui-selected, &.MuiToggleButtonGroup-grouped.Mui-selected + .MuiToggleButtonGroup-grouped.Mui-selected":
                {
                  borderColor: "neutral.main",
                },

              "&:focus": {
                outlineColor: "neutral.main",
              },
            },
          },
          "&.Mui-disabled": {
            opacity: 0.3,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: "auto",
          background: "transparent",

          "& .MuiTab-root": {
            background: theme.palette.background.paper,
            border: `${baseButtonStyle.borderWidth}px solid transparent`,
            borderColor: theme.palette.neutral.main,
            color: theme.palette.neutral.main,
            fontSize: convertPxToRem(12),
            fontWeight: 600,
            marginRight: 8,
            paddingBottom: 8,
            paddingTop: 8,
            minHeight: "auto",

            "&.Mui-focusVisible": {
              backgroundColor: theme.palette.background.paper,
            },

            "&.Mui-selected": {
              color: theme.palette.neutral.contrastText,
              background: theme.palette.neutral.main,
            },

            "&:last-child": {
              marginRight: 0,
            },

            "&.MuiTab-textColorPrimary": {
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              "&.Mui-selected": {
                color: theme.palette.primary.contrastText,
                background: theme.palette.primary.main,
              },
            },

            "&.MuiTab-textColorSecondary": {
              borderColor: theme.palette.secondary.main,
              color: theme.palette.secondary.main,
              "&.Mui-selected": {
                color: theme.palette.secondary.contrastText,
                background: theme.palette.secondary.main,
              },
            },
          },

          "& .MuiTabs-indicator": {
            display: "none",
          },
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 8,
          boxShadow: theme.shadows[shadows.light],

          "&::before": {
            backgroundColor: "transparent",
          },

          "&:first-of-type, &:last-of-type": {
            borderRadius: 8,
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: theme.spacing(8),
        }),
        content: () => ({ margin: 0, padding: 0 }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: convertPxToRem(12),
          fontWeight: 600,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          ...inputContainerDefaultStyle,
          input: {
            ...styleInputDefault,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 0,
          height: theme.spacing(5),
          width: theme.spacing(9),
        }),
        track: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.primary.main,
          borderRadius: theme.spacing(5),
          opacity: 0.5,

          ...(ownerState.disabled && {
            color: theme.palette.primary.contrastText,
          }),
        }),
        thumb: ({ ownerState, theme }) => ({
          height: theme.spacing(5 - 0.25 * 2),
          width: theme.spacing(5 - 0.25 * 2),

          ...(ownerState.disabled && {
            color: theme.palette.primary.contrastText,
          }),
        }),
        switchBase: ({ theme }) => ({
          padding: theme.spacing(0.25),

          "&.Mui-checked": {
            color: theme.palette.primary.contrastText,
            transform: `translateX(${theme.spacing(9 - 5)})`,

            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.primary.main,
              opacity: 1,
            },

            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
          },

          "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: theme.palette.primary.main,
            border: `${theme.spacing(1)} solid ${
              theme.palette.primary.contrastText
            }`,
          },
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: ({ theme }) => ({
          boxShadow: theme.shadows[shadows.light],
          "& .MuiList-root": {
            borderRadius: 2,
            py: 0,
            "& .MuiMenuItem-root": {
              borderRadius: 0,
            },
          },
        }),
      },
    },
  },
});

shadowList.forEach((shadowValue: string, index: number) => {
  theme.shadows[index] = shadowValue;
});

export const defaultFullscreenPageStyling = {
  minHeight: "100%",
  px: 2,
  py: 3,
  ...fullScreenBackground(
    `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.neutral["100"]})`
  ),
};

export const NavigationConfig = {
  navigationItemHeight: parseInt(theme.spacing(9), 10),
  navigationItemMinWidth: parseInt(theme.spacing(7), 10),
  navigationItemIconSize: 28,
  navigationItemLabelLineHeight: 15,
  padding: parseInt(theme.spacing(0), 10),
  floatingActionButtonWidth: parseInt(theme.spacing(7), 10),
  floatingActionButtonHeight: parseInt(theme.spacing(7), 10),
  floatingActionButtonSpacing: parseInt(theme.spacing(2), 10),
  boxShadow:
    "0px 4px 100px rgba(40, 44, 80, 0.25), 0px 15px 45px rgba(46, 75, 132, 0.27), 0px 1.87823px 5.6347px rgba(46, 75, 132, 0.135)",
};

export const GetHelpFABConfig = {
  floatingActionButtonWidth: parseInt(theme.spacing(7), 10),
  floatingActionButtonHeight: parseInt(theme.spacing(7), 10),
};

export const borderRadiusContainerConfig = {
  containerBorderRadiusMultiplier: 4,
};

function fullScreenBackground(background: string): object {
  return {
    "&::before": {
      background: background,
      content: "''",
      height: "100%",
      left: 0,
      pointerEvents: "none",
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: -1,
    },
  };
}

export const membershipManagementPageStyling = {
  minHeight: "100%",
  p: 2,
  ...fullScreenBackground(
    `linear-gradient(to bottom, ${theme.palette.background.paper}, ${theme.palette.neutral[100]})`
  ),
};

export const pageWithBottomNavigationStyling = {
  pb: `${
    NavigationConfig.navigationItemHeight +
    NavigationConfig.padding * 2 +
    NavigationConfig.floatingActionButtonHeight +
    NavigationConfig.floatingActionButtonSpacing
  }px`,
};

export const utilityButtonStyle = {
  ...baseButtonStyle,
  borderRadius: 1,
};

export const healthMeasurementListItemStyle = {
  sx: {
    alignItems: "center",
    appearance: "none",
    background: "transparent",
    border: "none",
    color: (theme: any) => theme.palette.neutral.dark,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",

    "&:disabled": {
      color: (theme: any) => theme.palette.neutral.dark,
    },
  },
};

export const HealthMeasurementValueRangeColors: any = {
  BodyMassIndex: {
    UNDERWEIGHT: theme.palette.primary[900],
    HEALTHY: theme.palette.accent1[700],
    OVERWEIGHT: theme.palette.secondary[700],
    OBESE: theme.palette.accent4.main,
    UNKNOWN: theme.palette.primary[100],
  },
  MetabolicAge: {
    GOOD: theme.palette.accent1[700],
    AVERAGE: theme.palette.secondary[700],
    BAD: theme.palette.accent4.main,
    UNKNOWN: theme.palette.primary[100],
  },
  SystolicBloodPressure: {
    GOOD: theme.palette.accent1[700],
    AVERAGE: theme.palette.secondary[700],
    HIGH: theme.palette.accent3[900],
    VERYHIGH: theme.palette.accent4.main,
    UNKNOWN: theme.palette.primary[100],
  },
  DiastolicBloodPressure: {
    GOOD: theme.palette.accent1[700],
    AVERAGE: theme.palette.secondary[700],
    VERYHIGH: theme.palette.accent4.main,
    DANGEROUS: theme.palette.accent5.main,
    UNKNOWN: theme.palette.primary[100],
  },
  Weight: {
    ANOREXIA: theme.palette.accent4.main,
    UNDERWEIGHT: theme.palette.secondary[700],
    GOOD: theme.palette.accent1[700],
    OVERWEIGHT: theme.palette.secondary[700],
    OBESE: theme.palette.accent4.main,
    UNKNOWN: theme.palette.primary[100],
  },
  BodyFat: {
    GOOD: theme.palette.accent1[700],
    OVERWEIGHT: theme.palette.secondary[700],
    OBESE: theme.palette.accent4.main,
    UNKNOWN: theme.palette.primary[100],
  },
  BodyWaterPercent: {
    BELOWAVERAGE: theme.palette.secondary[700],
    GOOD: theme.palette.accent1[700],
    ABOVEAVERAGE: theme.palette.secondary[700],
    UNKNOWN: theme.palette.primary[100],
  },
  MusclePercent: {
    GOOD: theme.palette.accent1[700],
    HIGH: theme.palette.secondary[700],
    VERYHIGH: theme.palette.accent4.main,
    UNKNOWN: theme.palette.primary[100],
  },
};

export const overrideDefaultLinkStyling: any = {
  textDecoration: "none",
  color: (theme: Theme) => theme.typography.h4.color,
};

export default theme;
